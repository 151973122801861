import {
  PermissionGroupDetails,
  PermissionGroups,
  PermissionLevel,
} from '../../interfaces/group-role';
import { User, UserUpdateRequest } from '../../interfaces/user';
import { MemberGroupRoles } from '../../services/group-roles';
import { findOne, getMemberBasesFloatPlanIds } from '../../services/trips';
import rules, {
  developerFeatures,
  onlyAllowInGroups,
  UserPermissions,
} from './roles';

export function checkUserPermissions({
  user,
  permissionGroups,
  action,
  data,
}: {
  user: User;
  permissionGroups: PermissionGroups | undefined;
  action: UserPermissions;
  data?: Record<string, unknown>;
}): boolean {
  // If the action is in the list of developer features and you're not a developer, then you don't have access to the feature
  if (!user?.developerMode && developerFeatures.includes(action)) {
    return false;
  }

  if (user?.role === 'super admin') {
    return checkByPermissionLevel('superAdmin', action);
  }

  if (!permissionGroups) {
    return false;
  }

  return Object.entries(permissionGroups).some(([permissionLevel, details]) =>
    checkByPermissionLevel(permissionLevel, action, details, data),
  );
}

function checkByPermissionLevel(
  permissionLevel: string,
  action: UserPermissions,
  details?: PermissionGroupDetails[],
  data?: Record<string, unknown>,
) {
  const permissions = rules[permissionLevel];
  if (!permissions) {
    // role is not present in the rules
    return false;
  }

  const staticPermissions = permissions.static;

  if (staticPermissions && staticPermissions.includes(action)) {
    // static rule not provided for action
    return true;
  }

  const dynamicPermissions = permissions.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition) {
      // dynamic rule not provided for action
      return false;
    }

    return permissionCondition(details ?? [], data);
  }
  return false;
}

export function transformIntoPermissionGroups(
  /** Note: this could be a whole user object with `groupRoles` or the response from _just_ fetching `groupRoles` */
  data?: MemberGroupRoles,
): PermissionGroups | undefined {
  if (!data) {
    return undefined;
  }
  const output: PermissionGroups = {};
  data.groupRoles.forEach((groupRole) => {
    if (groupRole.role === 'admin') {
      switch (groupRole.group.type) {
        case 'region':
          populatePermissionGroup('regionAdmin', groupRole, output);
          break;
        case 'emergency_service':
          populatePermissionGroup('emergencyServiceAdmin', groupRole, output);
          break;
        case 'base':
          populatePermissionGroup('groupAdmin', groupRole, output);
          break;
        case 'comms_support':
          populatePermissionGroup('commsSupportAdmin', groupRole, output);
          break;
        case 'office':
          populatePermissionGroup('officeAdmin', groupRole, output);
          break;
        case 'other':
          populatePermissionGroup('otherAdmin', groupRole, output);
        default:
          break;
      }
    } else if (groupRole.role === 'member') {
      switch (groupRole.group.type) {
        case 'region':
          populatePermissionGroup('regionMember', groupRole, output);
          break;
        case 'emergency_service':
          populatePermissionGroup('emergencyServiceMember', groupRole, output);
          break;
        case 'base':
          populatePermissionGroup('groupMember', groupRole, output);
          break;
        case 'comms_support':
          populatePermissionGroup('commsSupportMember', groupRole, output);
          break;
        case 'office':
          populatePermissionGroup('officeMember', groupRole, output);
          break;
        case 'other':
          populatePermissionGroup('otherMember', groupRole, output);
          break;
        default:
          break;
      }
    }
  });
  return output;
}

function populatePermissionGroup(
  permissionLevel: PermissionLevel,
  groupRole: MemberGroupRoles['groupRoles'][0],
  output: PermissionGroups,
) {
  if (!output[permissionLevel]) {
    output[permissionLevel] = [];
  }
  output[permissionLevel].push({ groupId: groupRole.group.id });
  groupRole.group.region_bases?.forEach((regionBase) => {
    output[permissionLevel].push({
      groupId: regionBase.information_group.id,
    });
  });
}

export function canAdministerGroup(
  user: Pick<User, 'role'>,
  permissionGroups: PermissionGroups,
  groupId?: string,
): boolean {
  if (user?.role === 'super admin') {
    return true;
  }
  if (!permissionGroups) {
    return false;
  }
  return [
    ...(permissionGroups.groupAdmin ?? []),
    ...(permissionGroups.regionAdmin ?? []),
    ...(permissionGroups.emergencyServiceAdmin ?? []),
    ...(permissionGroups.commsSupportAdmin ?? []),
    ...(permissionGroups.officeAdmin ?? []),
    ...(permissionGroups.otherAdmin ?? []),
  ].some((administerableGroup) => administerableGroup.groupId === groupId);
}
